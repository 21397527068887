import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlesGrid from "../components/articles-grid";
import Seo from "../components/seo";
import Headings from "../components/headings";

const CategoryPage = ({ data }) => {
  const category = data.strapiCategory;

  const allStrapiArticle = data.allStrapiArticle;

  return (
    <Layout>
      <Seo seo={{ metaTitle: category.name }} />
      <Headings title={category.name} description={""} />
      <main>
        <ArticlesGrid articles={allStrapiArticle.nodes} />
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiCategory(slug: { eq: $slug }) {
      id
      slug
      name
    }
    allStrapiArticle(filter: { category: { slug: { eq: $slug } } }) {
      nodes {
        ...ArticleCard
      }
    }
  }
`;

export default CategoryPage;
